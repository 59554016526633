import React from "react";
import Layout from "../components/Layout/Layout";
import ConsideringTreatment from "../components/Markup/ConsideringTreatment/ConsideringTreatment";
import SEO from "../components/seo";

const ConsiderTreatmentPage = () => {
  return (
    <Layout>
      <SEO name="considering-hep-b-treatment" baseName="considering-hep-b-treatment" />
      <ConsideringTreatment />
    </Layout>
  );
};
export default ConsiderTreatmentPage;
