import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation, { useTranslation } from "../../Translation";
import Image from "../../Image";
import Button from "../../Button";
import Link from "../../Link";
import Article from "../../Article";
import Headline from "../../Headline";
import Video from "../../Video";
import HorizontalRule from "../../HorizontalRule";
import ManagingHepB from "../Videos/ManagingHepB";

const ConsideringTreatment = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        effects: file(base: { eq: "effects.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <section id="considering-hep-b-treatment" className="main-section">
      <Article>
        <Headline>
          <h1>
            <Translation id="considering-treatment" />
          </h1>
        </Headline>
        <div className="px-4 pt-8">
          <div className="flex flex-col laptop:flex-row pb-8">
            <div className="w-full laptop:w-1/2 laptop:mr-4">
              <h2>
                <Translation id="good-news" />
              </h2>
              <h3 className="text-green">
                <Translation id="if-positive-treatments-available" />
              </h3>
              <p>
                <Translation id="talk-to-doctor-treatment" />
              </p>
            </div>
            <div className="w-full laptop:w-1/2">
              <ManagingHepB />
            </div>
          </div>
          <HorizontalRule />

          <h2>
            <Translation id="doctor-may-prescribe-antiviral" />
          </h2>
          <h3 className="text-green">
            <Translation id="antiviral-either-pill-or-inject" />
          </h3>
          <p>
            <Translation id="goal-is-to" />
          </p>
          <div>
            <Image
              fluid={data.effects.childImageSharp.fluid}
              alt={t("reduce-amount-of-virus-and-risk")}
              className="w-full laptop:w-1/2 mx-auto"
            />
            <div className="w-full laptop:w-1/2 mx-auto flex relative -mt-20 z-10 justify-between mb-20">
              <div className="w-28 text-center leading-none">
                <Translation id="reduce-amount-of-virus" />
              </div>
              <div className="w-28 text-center leading-none">
                <Translation id="reduce-risk-of-problems" />
              </div>
            </div>
          </div>
        </div>
        <div className="liver-slab tablet:-mx-4">
          <div className="px-4">
            <h3 className="text-white text-center">
              <Translation id="important-to-see-doctor-regularly" />
            </h3>
            <p className="text-white text-center">
              <Translation id="when-you-take-medicine" />
            </p>
            <div className="text-center my-8">
              <Button
                href={t("vemlidy-website")}
                color="white"
                size="large"
                analytics="'External Links','Clicked','Vemlidy Patient','Interaction','Product Information'"
                text={t("learn-about-a-treatment-option")}
                external
              />
            </div>
          </div>
        </div>
      </Article>
    </section>
  );
};
export default ConsideringTreatment;
